import './tailwind.output.css';
import './App.css';
import { useEffect, useState } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Dashboard from './components/Dashboard/Dashboard.tsx'; 
import axios from 'axios';
import ReadingMode from './components/ReadingMode/ReadingMode';

function App() {

  axios.defaults.headers.common.Accept = 'application/json';
  if (localStorage.getItem('access_token')){
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
  }

  return (
    <div className="App">
        <Switch>
        <Route exact path="/umvefinn"
          component={() => <Dashboard key={'umvefinn'} comp={'umvefinn'} />}
          /> 
        <Route exact path="/"
          component={() => <Dashboard key={'heim'} comp={'heim'} />}
          /> 

        <Route exact path="/lestrarhamur/:id/" component={ReadingMode} />

        <Route exact path="/verkefni"
          component={() => <Dashboard key={'verkefni'} comp={'verkefni'} />}
          />
        <Route exact path="/verkefni/:bookId"
          component={() => <Dashboard key={'kafli'} comp={'kafli'} />}
        />
        <Route exact path="/verkefni/:bookId/spurningar"
          component={() => <Dashboard key={'kafli'} comp={'kafli'} />}
        />
        <Route exact path="/dashboard"
          component={() => <Dashboard key={'dashboard'} comp={'dashboard'} />}
          /> 
        <Route exact path="/exams"
          component={() => <Dashboard key={'exams'} comp={'exams'} />}
          /> 
        <Route exact path="/endurgjof"
          component={() => <Dashboard key={'endurgjof'} comp={'endurgjof'} />}
          /> 

        </Switch>
    </div>
  );
}

export default App;
