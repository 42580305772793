import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import stories from '../Chapter/Stories.json';

interface RouteParams {
	id: string;
}

let story;

const ReadingMode: React.FC = () => {
	axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('apiKey')}`;
	const params = useParams<RouteParams>();
	story = getStoryById(params.id.toString());
	useEffect(
		() => {
			if (story) {

				let text = document.getElementById('para').innerHTML;

				console.log("text: " + text);

				text = text.substr(0, text.lastIndexOf("."));

				let split = text.split(".");

				for (let i = 0; i < split.length; i++) {

					split[i] = split[i] + ".";
					if (i % 3 === 0) {
						split[i] = '</span><span>' + split[i];
					}
				}

				let rejoined = split.join('');
				rejoined = rejoined + "</span>";
				rejoined = rejoined.slice(7);

				document.getElementById('para').innerHTML = rejoined;

				/* Text presentation */
				
				const textContainer = document.getElementById("para");
				const passagesList = textContainer.querySelectorAll("span");

				console.log(passagesList);

				document.onkeydown = checkKey;

				var currentPassage = 1;

				setVisibility();

				var canClick = true;

				console.log("start");

				function checkKey(e) {

					e = e || window.event;
					e.preventDefault();

					if (!canClick) {
						return;
					}

					if (canClick && (e.keyCode == '38' || e.keyCode == '40')) {

						canClick = false;

						setTimeout(function() {

							canClick = true;

						}, 300);

					}

					if (e.keyCode == '40') { //key down
						if (currentPassage != passagesList.length) {

							console.log("40");
							currentPassage = Math.min(currentPassage+1, passagesList.length);

						}
					} else if (e.keyCode == '38') {
						console.log("38");
						currentPassage = Math.max(currentPassage-1, 0);
						
					}
					if (0 < currentPassage && currentPassage < passagesList.length) {
						passagesList[currentPassage-1].scrollIntoView({ behavior: 'smooth', 
						block: 'center', inline: 'center' });
					}
					setVisibility();

				}
			
				function setVisibility () {
			
					let no = 0;
			
					while (no < passagesList.length) {
			
						if (no < currentPassage) {
							passagesList[no].style.opacity = 1;
						} else {
							passagesList[no].style.opacity = 0.1;
						}
						no++;
					}
			
				}
			
			}

		},
		[ story]
	);

	function getStoryById(storyId : number) {
		const filteredStories = stories.filter(
			function(stories){ return stories.id == storyId }
		);
		if (filteredStories.length > 0) {
			return filteredStories[0];
		} else {
			return null;
		}
	}

	return (
		<div id="reading">
			{console.log("story: " + story)}
			<p className="intro">Þú ert núna í lestrarham. Notaðu örvatakkana UPP og NIÐUR til þess að lesa textann.</p>
			{story && (
				<Link to={`/verkefni/${params.id}/`} className="small-button-reading">
					Til baka
				</Link>
			)}
			{story ? (
				<div className="chapterInfo">
					<h1>{story.title}</h1>
					<p id="para">
						{story.text.map(function(item, idx) {
							return (
								<React.Fragment key={'p-' + idx}>
									{item.replace(/<\/?[^>]+(>|$)/g, "")}
									<div className="linebreak" />
								</React.Fragment>
							);
						})}
					</p>
				</div>
			) : (
				''
			)}
		</div>
	);
};

export default ReadingMode;
