import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import tumi from '../../dalle/tumi.png';
import running from '../../dalle/running.png';
import gosid from '../../dalle/gosid.png';
import gosid2 from '../../dalle/gosid2.png';
import gilitrutt from '../../dalle/gilitrutt.png';
import klifurfelagid from '../../dalle/klifurfelagid.png';
import pasto from '../../dalle/pasto.png';
import dukur from '../../dalle/dukur.png';
import krummar from '../../dalle/krummar.png';
import sendibref from '../../dalle/sendibref.png';
import anton from '../../dalle/anton.png';
import vegan from '../../dalle/vegan.png';
import giraffe1 from '../../dalle/giraffe1.png';
import giraffe2 from '../../dalle/giraffe2.png';
import sandskrimsli1 from '../../dalle/sandskrimsli1.png';
import sandskrimsli2 from '../../dalle/sandskrimsli2.png';
import thjofur from '../../dalle/thjofur.png';
import maya from '../../dalle/maya.png';
import floskuskeytid from '../../dalle/floskuskeytid.png';
import unglingaveiki from '../../dalle/unglingaveiki.png';
import nastrond from '../../dalle/nastrond.png';
import baekur from '../../dalle/baekur.png';
import svanur from '../../dalle/svanur.png';

import stories from './Stories.json';

interface RouteParams {
	id: string;
}

const Chapter: React.FC = () => {
	const params = useParams<RouteParams>();
	const location = useLocation().pathname;
	axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('apiKey')}`;
	const [ currentExercise, setCurrentExercise ] = useState<any>(0);
	const [ correctAnswers, setCorrectAnswers ] = useState<any>({});

	const images = {
		Tumi: tumi,
		running: running,
		gosid: gosid,
		gosid2: gosid2,
		klifurfelagid: klifurfelagid,
		gilitrutt: gilitrutt,
		pasto: pasto,
		dukur: dukur,
		krummar: krummar,
		sendibref: sendibref,
		anton: anton,
		giraffe1: giraffe1,
		giraffe2: giraffe2,
		vegan: vegan,
		sandskrimsli1: sandskrimsli1,
		sandskrimsli2: sandskrimsli2,
		thjofur: thjofur,
		floskuskeytid: floskuskeytid,
		unglingaveiki: unglingaveiki,
		maya: maya,
		nastrond: nastrond,
		baekur: baekur,
		svanur: svanur
	};

	useEffect(() => {
		window.scrollTo(0, 0)
	  }, [])

	let exerciseStarted = false;

	function changeButtonCSS(id: number) {
		let element = document.getElementById('exerciseTypeSelection-' + id);
		element?.classList.remove('exerciseTypeUnselected');
		element?.classList.add('exerciseTypeSelection');
		let idx = 0;
		while (true) {
			if (idx === id) {
				idx++;
				continue;
			}
			let button = document.getElementById('exerciseTypeSelection-' + idx);
			if (button === null || button === undefined) {
				break;
			} else {
				button?.classList.remove('exerciseTypeSelection');
				button?.classList.add('exerciseTypeUnselected');
			}
			idx++;
		}
	}
			
	function getStoryById(storyId : number) {
		const filteredStories = stories.filter(
			function(stories){ return stories.id == storyId }
		);
		if (filteredStories.length > 0) {
			return filteredStories[0];
		} else {
			return null;
		}
	}

	function removeAllAnswers() {
		story?.spurningar[currentExercise].content.forEach(function(item, idx) {
			for (let answer = 0; answer < 4; answer++) {
				let element = document.getElementById(`answer-${idx+1}-${answer}}`);
				if (element === null || element === undefined) {
					break;
				}
				element.checked = false;
			}
			idx++;
		});
	}

	function considerSwitch(idx) { 
		if (exerciseStarted) {
			if (!window.confirm("Þú byrjaðir á verkefninu, viltu hætta við og byrja á öðru?")) {
				return;
			}
		}

		setCorrectAnswers({});

		window.scrollTo(0, 0); 
		if (currentExercise !== idx) {
			removeAllAnswers();
		}
		setCurrentExercise(idx);
		changeButtonCSS(idx);
		exerciseStarted = false;
	}

	function getAllAnswers() {

		let answers = {};
		// the values of the correct answers:
		let corrects = {};
		let actualAnswers = {};

		story?.spurningar[currentExercise].content.forEach(function(item, idx) {
			for (let answer = 0; answer < 4; answer++) {
				const element = document.getElementById(`answer-${idx+1}-${answer}}`);
				if (element === null || element === undefined) {
					break;
				}
				console.log("element: " + element.innerHTML);
				if(element.checked) {
					answers[idx + 1] = element.value.split("|")[1];
					actualAnswers[idx + 1] =  element.value.split("|")[0];
					corrects[idx + 1] = item.correct;
				}
			}
			idx++;
		});

		const result = {}
		result['bookId'] = params.bookId;
		result['exerciseId'] = currentExercise;
		result['answers'] = answers;
		result['corrects'] = corrects;
		result['actualAnswers'] = actualAnswers;
		result['nQuestions'] = story?.spurningar[currentExercise].content.length;

		return result;

	}

	//colors the correct answers green and the incorrect answers red
	function colorAnswers(answers) {
		console.log("coloring answers");
		console.log(JSON.stringify(answers));
		const newAnswers = {};
		for (const key in answers.answers){
			const value = answers.answers[key];
			console.log("value: " + value + " key: " + key + "");
			newAnswers[parseInt(key)-1] = {value: value === "true" ? "greenQuestion" : "redQuestion", 
					correct: answers.corrects[key],
					actualAnswer: answers.actualAnswers[key] };
		}
		setCorrectAnswers(newAnswers);
	}

	function submitAnswers() {

		let answers = getAllAnswers();

		if (Object.keys(answers.answers).length !== story?.spurningar[currentExercise].content.length) {

			if (!window.confirm("Þú hefur ekki svarað öllum spurningunum, viltu halda áfram?")) {
				return;
			}

		}

		let existingAnswers = localStorage.getItem('exams');
		
		if (existingAnswers === null) {
			existingAnswers = JSON.stringify([answers]);
		} else {
			existingAnswers = JSON.parse(existingAnswers);
			existingAnswers.push(answers);
			existingAnswers = JSON.stringify(existingAnswers);
		}

		colorAnswers(answers);


		localStorage.setItem('exams', existingAnswers);

		console.log("correctAnswers: " + JSON.stringify(correctAnswers));

		alert("Takk fyrir svörin. Þú svaraðir " + Object.keys(answers.answers).filter(x => answers.answers[x] === "true").length + " af " + answers.nQuestions + " spurningum rétt.");
		removeAllAnswers();

	}

	const story = getStoryById(params.bookId);
	const exerciseMode = location.includes("spurningar");

	return (
		
		<div className="chapterWrapper">
			<div className="content content-small">
				<div>
					<div className="chapterContent">
						<div>
							<div className={"exerciseTitle"}>📖 {story.title}</div>
							{exerciseMode ? <div className={"exerciseSubtitle"}>📚 Verkefnabanki</div> : ''}
						</div>
					</div>
					{exerciseMode ? story.spurningar.map(function(item, idx) {
						return <button 
							id={'exerciseTypeSelection-' + idx}
							onClick={(e) => { 
								considerSwitch(idx);
							}
						} 
						className={idx === 0 ? "exerciseTypeSelection" : "exerciseTypeUnselected"}>{item.title}</button>}) 
						: 
						''
					}
				</div>
				<div className="chapterContent">
					{!exerciseMode && (
						<Link to={`/lestrarhamur/` + params.bookId} className={'readingModeSelection'}>
							<button className="button-reading">Lestrarhamur</button>
						</Link>
					)}

					{!exerciseMode && <div className="description">Þessi saga er á stigi {story.level}.</div>}
					{!exerciseMode && story.specialwords && <div className="specialwords">Orð til að gaumgæfa: <mark className="orange">{story.specialwords.join(", ")}</mark></div>}
					<p>
						{(!exerciseMode) ? 
							story.text.map(function(item, idx) {
							return <span > {story.imageidx.includes(idx) ? <div className="figureholder">
								<img className="centerImage" alt={story.imagecaption[story.imageidx.indexOf(idx)]} src={images[story.imagename[story.imageidx.indexOf(idx)]]} />
								<div className="imageCaption">{story.imagecaption[story.imageidx.indexOf(idx)]}</div>
							</div> : ''}<div dangerouslySetInnerHTML={{ __html:item}}></div></span>;
							})
						: story.spurningar[currentExercise].content.map(function(item, idx) {
							return <p>
								<div className={correctAnswers[idx] ? correctAnswers[idx]["value"] : 'otherQuestion'}>{idx+1}. {item.question}</div>
								{(item.answers.length > 2 ? item.answers.sort(() => Math.random() - 0.5) : item.answers).map((answer, answerIdx) => (
									<div>
										<input
											id={`answer-${idx+1}-${answerIdx}}`}
											type="radio"
											key={`answer-${idx+1}-${answerIdx}}`}
											name={""+idx+1}
											value={answer + "|" +(answer===item.correct)}
											onClick={function() { exerciseStarted = true; }}
										/>
										<label
											htmlFor={`answer-${idx+1}-${answerIdx}}`}
										><div
										className = {correctAnswers[idx] ? (correctAnswers[idx]["correct"] === answer ? "correctAnswer" : (correctAnswers[idx]["actualAnswer"] === answer ? 'incorrectAnswer' : '')) : ''}
										>{answer}</div></label>
									</div>
								))}
							</p>;
						})}
					</p>
					<ul />
				{exerciseMode && <div>
					<button
						className="button-submit"
						onClick={function() {
							window.scrollTo(0, 0);
							submitAnswers();
						}}
					>
						Skila inn
					</button>
				</div>}
				</div>
			</div>
			<br />
			<Link to={`/verkefni`} className="small-button" onClick={() => {window.scrollTo(0, 0)}}>
				Til baka
			</Link>
			{ !exerciseMode ?
			<Link to={`/verkefni/${params.bookId}/spurningar`} className="small-button" onClick={() => {window.scrollTo(0, 0)}}>
				Verkefni
			</Link>
			: ''
			}
		</div>
	);
};

export default Chapter;
