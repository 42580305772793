import React from 'react';
import Header from '../Header/Header';
import styles from './Dashboard.module.scss';
import Books from '../Books/Books';
import Chapter from '../Chapter/Chapter';
import Feedback from '../Feedback/Feedback';
import Login from '../Login/Login';
import About from '../About/About';

interface PropTypes {
	comp: string;
}

const Dashboard: React.FC<PropTypes> = ({ comp }) => {
	//const [ token, setToken ] = useState(AuthService.getCurrentToken());

	//if (token === null) history.push('/heim');

	return (
		<div className={styles.Dashboard}>
			<div className={'flex'}>
				<Header active={comp} />
				<div className="flex flex-col w-0 flex-1">
					{comp === 'verkefni' ? <Books /> : ''}
					{comp === 'kafli' ? <Chapter /> : ''}
					{comp === 'endurgjof' ? <Feedback /> : ''}
					{comp === 'heim' ? <Login /> : ''}
					{comp === 'umvefinn' ? <About /> : ''}
				</div>
				<div className={'footer'}>Ⓒ MÁLBORG 2023</div>
			</div>
		</div>
	);
};

export default Dashboard;
