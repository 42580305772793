import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.scss';

const Header: React.FC<{ active: string }> = ({ active }) => {
	const location = useLocation().pathname.split("/")[1];

	return (
		<React.Fragment>
			<div className="header">
				<div className="logo">
				<Link to="/"><h3>Ísleifur</h3></Link>
				</div>
				<div className="header-links">
					<div>
						<React.Fragment>
							<div className= {location === "" ? "selected-header-link" : "header-link" }>
								<Link to="/">Heim</Link>
							</div>
							<div className= {(location === "verkefni") ? "selected-header-link" : "header-link" }>
								<Link to="/verkefni">Verkefni</Link>
							</div>
							<div className= {location === "endurgjof" ? "selected-header-link" : "header-link" }>
								<Link to="/endurgjof">Endurgjöf</Link>
							</div>
							<div className= {location === "umvefinn" ? "selected-header-link" : "header-link" }>
								<Link to="/umvefinn">Um vefinn</Link>
							</div>
						</React.Fragment>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Header;
