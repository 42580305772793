import React from 'react';
import { Link } from 'react-router-dom';
import tumi from '../../dalle/smaller/tumi.png';
import running from '../../dalle/smaller/running.png';
import gosid from '../../dalle/smaller/gosid.png';
import gosid2 from '../../dalle/smaller/gosid2.png';
import gilitrutt from '../../dalle/smaller/gilitrutt.png';
import klifurfelagid from '../../dalle/smaller/klifurfelagid.png';
import pasto from '../../dalle/smaller/pasto.png';
import dukur from '../../dalle/smaller/dukur.png';
import krummar from '../../dalle/smaller/krummar.png';
import sendibref from '../../dalle/smaller/sendibref.png';
import anton from '../../dalle/smaller/anton.png';
import vegan from '../../dalle/smaller/vegan.png';
import giraffe1 from '../../dalle/smaller/giraffe1.png';
import giraffe2 from '../../dalle/smaller/giraffe2.png';
import sandskrimsli1 from '../../dalle/smaller/sandskrimsli1.png';
import sandskrimsli2 from '../../dalle/smaller/sandskrimsli2.png';
import thjofur from '../../dalle/smaller/thjofur.png';
import floskuskeytid from '../../dalle/smaller/floskuskeytid.png';
import maya from '../../dalle/smaller/maya.png';
import unglingaveiki from '../../dalle/smaller/unglingaveiki.png';
import nastrond from '../../dalle/smaller/nastrond.png';
import baekur from '../../dalle/smaller/baekur.png';
import svanur from '../../dalle/smaller/svanur.png';

import stories from '../Chapter/Stories.json';

const Books: React.FC = () => {
	const levels = [1, 2, 3, 4, 5];

	const levelText = ["Gott fyrir þá sem kunna að lesa en vilja bæta sig.", 
			"Fyrir þá sem finnst fyrsta þrep frekar auðvelt.",
			"Hér fer róðurinn að þyngjast.",
			"Hentar þeim sem eru á framabraut.",
			"Gæti hentað snillingum."];

	const images = {
		Tumi: tumi,
		running: running,
		gosid: gosid,
		gosid2: gosid2,
		klifurfelagid: klifurfelagid,
		gilitrutt: gilitrutt,
		pasto: pasto,
		dukur: dukur,
		krummar: krummar,
		sendibref: sendibref,
		vegan: vegan,
		anton: anton,
		giraffe1: giraffe1,
		giraffe2: giraffe2,
		sandskrimsli1: sandskrimsli1,
		sandskrimsli2: sandskrimsli2,
		thjofur: thjofur,
		floskuskeytid: floskuskeytid,
		unglingaveiki: unglingaveiki,
		maya: maya,
		nastrond: nastrond,
		baekur: baekur,
		svanur: svanur
	};
	
	function bookEnter(e : any) {

		const idx = Number(e.target.getAttribute("idxBook"));

		let siblingBooks = [];

		Array.from(siblingBooks).forEach((x)=>{
			if (Number(x.getAttribute("idxBook")) !== idx) {
				if (x.getAttribute("containsDescription")) {
					x.style.opacity = 0;
				} else {
					x.classList.add("hiddenBook");
					x.classList.remove("visibleBook");
				}
			}
		});
		
	}
	function bookLeave(e) {

		const color = e.target.getAttribute("colorBook");

		let siblingBooks = document.getElementsByClassName("book" + color);

		Array.from(siblingBooks).forEach((x,i)=>{
			if (x.getAttribute("containsDescription")) {
				x.style.opacity = 1;
			} else {
				x.classList.remove("hiddenBook");
				x.classList.add("visibleBook");
			}
		});


	}

	return (
		<div className="bookParent loginbackground">
			{levels.map(level => (
				<div className={'books-display'}>
					{stories.filter(story => story.level === level).map(function(story, idx) {
						return (
							story.level === level ? 
								<Link to={`/verkefni/${story.id}`} onMouseEnter={bookEnter} onMouseLeave={bookLeave} className={"small-button book-button"}>
									{idx === 0  ? (
									<div className="levelinfo">
										<div className="levelheader">Þrep {level}</div>
										<div className="leveldescription">{levelText[level-1]}</div>
									</div>) : ''}
									<div idxBook={idx} colorBook={story.color} className={`book` + story.color + " book"} onMouseLeave={bookLeave}>
										<div idxBook={idx} colorBook={story.color} className={`book` + story.color + " page5"}></div>
										<div idxBook={idx} colorBook={story.color} containsDescription="true" className={`book` + story.color + " page4"}><div className="bookdescription"><div key={story.description} className="storydescription">{story.description}</div></div></div>
										<div idxBook={idx} colorBook={story.color} className={`book` + story.color + " page2"}></div>
										<div idxBook={idx} colorBook={story.color} className={`book` + story.color + " page1"}></div>
										<div idxBook={idx} colorBook={story.color} className={`book` + story.color + " front"} style={{ background: story.color }}><div className="booktitle">{story.title}</div><img className="coverImage" alt={story.imagecaption[story.imageidx.indexOf(idx)]} src={images[story.imagename[0]]} /></div>
										<div idxBook={idx} colorBook={story.color} className={`book` + story.color + " binding"}></div>
									</div>
								</Link>
								: ''
						);
					})}
				</div>
			))}
			<div className={"copyright"}>Ⓒ MÁLBORG 2023</div>
		</div>
	);
};

export default Books;
