import { FunctionComponent } from 'react';
import background from '../../static/isleifur.gif';

const Login: FunctionComponent<{}> = () => {

	return (
		<div className={'loginbackground'}>
			<div className={'login-image-placeholder'} style={{ backgroundImage: `url(${background})` }} />
		</div>
	);
};

export default Login;
