import { FunctionComponent } from 'react';

const About: FunctionComponent<{}> = () => {

	return (
		<div className="loginbackground">
			<div className={'about-wrapper'}>
				<div className={'about-wrap'}>
					<div className={'aboutText'}>Um vefinn</div>
					<div className={'aboutDiv'}> Vefurinn Ísleifur er hálfgert stafrænt hugsjónarverk sem Málborg ehf. gefur út með styrki úr Þróunarsjóði námsgagna. Ísleifur er öllum opinn og aðgangur ókeypis. Efni hans er einkum ætlað nemendum á mið- og unglingastigi grunnskóla en getur að sjálfsögðu nýst öðrum nemendahópum.</div>
					<div className={'aboutDiv'}>Vefurinn Ísleifur er afrakstur þróunarvinnu. Sú vinna á vonandi á eftir að skila sér í fjölbreyttari útgáfu námsefnis af þessu tagi þar sem ný tækni er nýtt og prófuð.</div>
					<div className={'aboutDiv'}>Við gerð Ísleifs voru notaðar tækninýjungar til þess að:</div>
					<div className={'aboutDivBullet'}> • myndskreyta sögur með nýja gervigreindarforritinu DALL-E frá OpenAI.</div>
					<div className={'aboutDivBullet'}> • fá hugmyndir að verkefnum og jafnvel sögum með nýja gervigreindarforritinu frá OpenAI sem heitir GPT 3.5.</div>
					<div className={'aboutDiv'}> • geta sett textann í lestarham. Lestrarhamurinn er hannaður sérstaklega til að þjálfa lesskilning.</div>
					<div className={'aboutDiv'}>Vefurinn inniheldur tuttugu sögur, nítján frumsamdar og eina þjóðsögu. Þær eru allar skreyttar myndum sem tengjast efni þeirra. Sögurnar tuttugu eru á fimm mismunandi þyngdarstigum. Hverri sögu fylgja stafræn verkefni sem ætlað er að dýpka skilning nemenda, efla lesskilning, auka eftirtekt og orðaforða. Sumum sögunum fylgja verkefni til að þjálfa stafsetningu. Nemendur geta nálgast niðurstöður sínar jafnóðum með því að smella á þar til gerðan hnapp.</div>
					<div className={'aboutDiv'}>Rannís er þakkað kærlega fyrir þróunarstyrk sem styður nýsköpun, þróun og gerð námsefnis á stafrænu formi. Án styrksins hefði ekkert orðið úr hugmyndinni um Ísleif.</div>

					<h3>Nokkur orð um Ísleif í kennslu</h3>
					<div className={'aboutDiv'}>Árið 1934 skrifar Aðalsteinn Eiríksson um námsefni: „Kennslu skal miða að því, að glæða námfýsi nemenda og koma þeim til að starfa á eigin hönd, og skal um námsefni jafnan höfð hliðsjón af því, hvað ætla má að komi nemöndum [svo] að gagni í lífinu“ (Aðalsteinn Eiríksson, 1934, bls. 57). </div>
					<div className={'aboutDiv'}>Segja má að sögunum og stafrænu verkefnunum hér á þessum vef sé ætlað að glæða námsfýsi nemenda og samtímis auka hæfni þeirra í læsi. Námsefni eitt og sér dugar þó skammt og ekki heldur leiðbeiningar um notkun þess. Námsfýsi eða áhugi er forsenda þess nemendur lesi sér til ánægju og gagns. Hægt er að ýta undir áhugann með ýmsum hætti og mögulegar leiðir eru óþrjótandi í  kennslu þar sem sköpun og fjölbreytni ræður ferðinni.</div>
					<div className={'aboutDiv'}>Gert er ráð fyrir að texti og verkefni á þyngdarstigi eitt henti nemendum sem kunna að lesa en þurfa að auka hæfni sína. Textar og verkefni á þyngdarstigi fimm eru erfiðastir og reyna sumir hverjir á töluverða hæfni í lesskilningi.</div>
					<div className={'aboutDiv'}>Í upphafi hverrar sögu er vakin athygli á nokkrum orðum til að gaumgæfa. Þeim er skipt í orðhluta til að auðvelda nemendum að ná merkingu þeirra og auka skilning á hvernig þau eru byggð upp. Mælt er með að nemendur séu hvattir til að skoða þessi orð vel og fletta upp merkingu þeirra.</div>
					<div className={'aboutDiv'}>Með því að smella á hnappinn lestrarhamur breytist snið textans. Aðeins lítill hluti sögunnar birtist í einu. Með því að ýta á örvatakkana er hægt að fá textann birtan á eigin hraða. Rannsóknir sýna að þessi aðferð hjálpi nemendum við lestur.</div>
					<h3>Um höfunda Ísleifs</h3>
					<div className={'aboutDivBullet'}><b>Svanhildur Kr. Sverrisdóttir</b></div>
					<div className={'aboutDivAuthors'}>Svanhildur er þaulreyndur námsefnishöfundur. Hún hefur áratuga reynslu af kennslu á öllum skólastigum, námsefnisgerð, ritstörfum, ritstjórn og útgáfu. Svanhildur er aðalhöfundur Ísleifs.</div>
					<div className={'aboutDivBullet'}><b>Sverrir Þorgeirsson</b></div>
					<div className={'aboutDivAuthors'}>Sverrir er doktornsnemi í tölvunarfræði við ETH í Zürich, Sviss. Sverrir er meðhöfundur Ísleifs en hann sá um helstu þætti hugbúnaðarferlisins; það er kerfisgreiningu og -hönnun, forritun og villuprófanir.</div>
					<h3>Hafðu samband</h3>

					<div className={'aboutDiv'}>Sendu gjarnan tölvupóst til útgáfunnar Málborgar ef þú ert með ábendingu eða fyrirspurn um Ísleif. Netfangið er malborg@malborg.is.</div>

				</div>
			</div>
		</div>
	);
};

export default About;
