import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import stories from '../Chapter/Stories.json';

const Feedback: React.FC = () => {

	const { data: currentUserExams } = useQuery<any>('currentUserExams', () =>
		localStorage.getItem('exams')
	);
	const [ shouldGrade, setShouldGrade ] = useState<Boolean>(true);

	const endurgjof = [
		[1, "Þetta kemur næst!"],
		[2, "Þetta kemur næst!"],
		[3, "Þetta kemur næst!"],
		[4, "Reyndu aftur!"],
		[5, "Þetta gat gengið betur"],
		[6, "Ekki alveg nógu gott!"],
		[7, "Sæmilegt"],
		[8, "Nokkð gott"],
		[9, "Aldeilis gott!!"],
		[10, "Frábært!"]
	  ];

	function scoreToFeedback(assignment, nQuestions) {

		const scoreNumber = (assignment.match(/true/g) || []).length;
		const adjustedScore = scoreNumber * 10 / nQuestions;

		for (let i = 0; i < endurgjof.length; i++) {
			if (adjustedScore <= endurgjof[i][0]) {
				return scoreNumber + " stig af " + nQuestions + ": " + endurgjof[i][1];
			}
		}
		
		return scoreNumber + " stig af " + nQuestions + ": " + endurgjof[endurgjof.length-1][1];
	}

	function getAverageGrade(assignments, type, level) {
		let correct = 0;
		let total = 0;
		assignments.forEach((assignment) => {
			if (assignment.type === type && assignment.level === level) {
				correct += (JSON.stringify(assignment.answers).match(/true/g) || []).length;
				total += Number(assignment["nQuestions"]);
			}
		});
		return (total > 0) ? Math.round(100*(correct/(total))) + "%" : "___";
	}

	function formatDate(string: string) {
		return new Date(string).toLocaleDateString('en-GB');
	}

	function removeDuplicates(assignments) {
		
		let result = []

		assignments.forEach((assignment) => {
			let duplicate = false;
			result.forEach((resultAssignment) => {
				if (assignment.bookId === resultAssignment.bookId && assignment.exerciseId === resultAssignment.exerciseId) {
					duplicate = true;
				}
			});
			if (!duplicate) {
				result.push(assignment);
			}
		});

		return result;
	}

	function parseAssignments(assignments) {

		let parsedAssignments = [];
		JSON.parse(assignments).forEach((input) => {

			const assignment = input["answers"];
			const story = stories.filter(x => x.id === Number(input.bookId))[0];

			let parsedAssignment = {};
			parsedAssignment["bookTitle"] = story.title;
			parsedAssignment["bookId"] = input.bookId;
			parsedAssignment["exerciseId"] = input.exerciseId;
			parsedAssignment["title"] = story.spurningar[Number(input.exerciseId)].title;
			parsedAssignment["type"] = story.spurningar[Number(input.exerciseId)].type;
			parsedAssignment["level"] = story.level;
			parsedAssignment["answers"] = assignment;
			parsedAssignment["nQuestions"] = input.nQuestions;
			parsedAssignment["dateCreated"] = input.dateCreated;
			parsedAssignments.push(parsedAssignment);
		});
		return parsedAssignments;
	}

	return (
		<div className="bookParent loginbackground">
			<div className={"feedbackTable"}>
				<div>
					<div className="profileGrades">
						{currentUserExams !== null && currentUserExams !== undefined && currentUserExams.length > 0 ? (
						(<div className={"table-responsive"}><table key={1} className={"centerTable"}>
							<tbody>
								<tr>
								<td><h1>📖 Lesefni</h1></td>
								<td><h1>📚 Verkefni</h1></td>
								<td><h1>🍎 Árangur</h1></td>
								</tr>
							</tbody>
						</table></div>)) : <h1>Engin <Link to={`/verkefni`} className={"linkStyle"}>verkefni</Link> leyst ennþá.</h1>}
							{currentUserExams ? (
								removeDuplicates(parseAssignments(currentUserExams)).map((assignment, index) => (
									<div className={"table-responsive"}><table key={index} className={"centerTable"}>
										<tbody>
											<tr>
											<td><Link to={`/verkefni/${assignment.bookId}`} className={'bookFont'}>{assignment.bookTitle}</Link> ({assignment.title})</td>{}
											<td><div>{assignment.title}</div></td>
											<td>{shouldGrade ? scoreToFeedback(JSON.stringify(assignment.answers), Number(assignment.nQuestions)):<i>Ekki farið yfir ennþá</i>}</td>
											</tr>
										</tbody>
									</table></div>
								))
							) : (
								''
							)}
						{currentUserExams !== null && currentUserExams !== undefined && currentUserExams.length > 0 ? (
						(<div>
							<div className={'endurgjof-display'}>
								Samantekt
								</div>
							<div className={"table-responsive"}><table key={1}>
									<tbody>
										<tr>
											<td><h1>Stig</h1></td>
											<td><h1>Stafsetning</h1></td>
											<td><h1>Orðaforði</h1></td>
											<td><h1>Lesskilningur</h1></td>
										</tr>
										<tr>
											<td><h1>1</h1></td>
											<td><h1>{shouldGrade ? getAverageGrade(removeDuplicates(parseAssignments(currentUserExams)), "grammar", 1) : "?"}</h1></td>
											<td><h1>{shouldGrade ? getAverageGrade(removeDuplicates(parseAssignments(currentUserExams)), "vocabulary", 1) : "?"}</h1></td>
											<td><h1>{shouldGrade ? getAverageGrade(removeDuplicates(parseAssignments(currentUserExams)), "comprehension", 1) : "?"}</h1></td>
										</tr>
										<tr>
											<td><h1>2</h1></td>
											<td><h1>{shouldGrade ? getAverageGrade(removeDuplicates(parseAssignments(currentUserExams)), "grammar", 2) : "?"}</h1></td>
											<td><h1>{shouldGrade ? getAverageGrade(removeDuplicates(parseAssignments(currentUserExams)), "vocabulary", 2) : "?"}</h1></td>
											<td><h1>{shouldGrade ? getAverageGrade(removeDuplicates(parseAssignments(currentUserExams)), "comprehension", 2) : "?"}</h1></td>
										</tr>
										<tr>
											<td><h1>3</h1></td>
											<td><h1>{shouldGrade ? getAverageGrade(removeDuplicates(parseAssignments(currentUserExams)), "grammar", 3) : "?"}</h1></td>
											<td><h1>{shouldGrade ? getAverageGrade(removeDuplicates(parseAssignments(currentUserExams)), "vocabulary", 3) : "?"}</h1></td>
											<td><h1>{shouldGrade ? getAverageGrade(removeDuplicates(parseAssignments(currentUserExams)), "comprehension", 3) : "?"}</h1></td>
										</tr>
										<tr>
											<td><h1>4</h1></td>
											<td><h1>{shouldGrade ? getAverageGrade(removeDuplicates(parseAssignments(currentUserExams)), "grammar", 4) : "?"}</h1></td>
											<td><h1>{shouldGrade ? getAverageGrade(removeDuplicates(parseAssignments(currentUserExams)), "vocabulary", 4) : "?"}</h1></td>
											<td><h1>{shouldGrade ? getAverageGrade(removeDuplicates(parseAssignments(currentUserExams)), "comprehension", 4) : "?"}</h1></td>
										</tr>
										<tr>
											<td><h1>5</h1></td>
											<td><h1>{shouldGrade ? getAverageGrade(removeDuplicates(parseAssignments(currentUserExams)), "grammar", 5) : "?"}</h1></td>
											<td><h1>{shouldGrade ? getAverageGrade(removeDuplicates(parseAssignments(currentUserExams)), "vocabulary", 5) : "?"}</h1></td>
											<td><h1>{shouldGrade ? getAverageGrade(removeDuplicates(parseAssignments(currentUserExams)), "comprehension", 5) : "?"}</h1></td>
										</tr>
									</tbody>
								</table></div>
						</div>)) : ''}	
					</div>
				</div>
			</div>
		</div>
	);
};

export default Feedback;
